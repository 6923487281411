import { LitElement, css, html } from 'lit'
import { createRef, ref } from 'lit/directives/ref.js'
import Hls from 'hls.js'
import Plyr from 'plyr'
import styles from 'plyr/dist/plyr.css'

export class VideoPlayer extends LitElement {
  static get properties () {
    return {
      videoUrl: { type: String },
      iconUrl: { type: String },
      currentTime: { type: Number },
      trackingUrl: { type: String }
    }
  }

  constructor () {
    super()
    this.iconUrl = window.svgSprite
  }

  static styles = [styles, css`
    :root {
      --plyr-color-main: var(--brand-color);
    }
  `]

  videoRef = createRef()

  firstUpdated () {
    const plyr = new Plyr(
      this.videoRef.value,
      {
        iconUrl: this.iconUrl,
        loadSprite: this.loadSprite,
        controls: [
          'play-large',
          'play',
          'progress',
          'current-time',
          'duration',
          'mute',
          'volume',
          'captions',
          'settings',
          'airplay',
          'fullscreen'
        ],
        captions: {
          language: window.language
        }
      }
    )

    // Set the current time of the video after it has loaded
    plyr.once('loadedmetadata', (event) => {
      plyr.currentTime = this.currentTime
    })

    plyr.on('timeupdate', (event) => {
      const currentTime = Math.floor(event.detail.plyr.currentTime)
      this.trackCurrentTime(currentTime)
    })

    if (Hls.isSupported()) {
      const hls = new Hls()
      hls.loadSource(this.videoUrl)
      hls.attachMedia(this.videoRef.value)
    }
  }

  trackCurrentTime (currentTime) {
    const csrftoken = window.csrfToken || document.querySelector('[name=csrfmiddlewaretoken]').value

    if (currentTime % 10 === 0) {
      fetch(this.trackingUrl, {
        method: 'PUT',
        headers: {
          'X-CSRFToken': csrftoken,
          'Content-Type': 'application/json'
        },
        credentials: 'same-origin',
        body: JSON.stringify({
          currentTime
        })
      })
    }
  }

  getCaptions () {
    return {
      en: this.videoUrl.replace('.m3u8', '-lang-en.vtt'),
      de: this.videoUrl.replace('.m3u8', '-lang-de.vtt')
    }
  }

  render () {
    const captions = this.getCaptions()
    return html`
      <link rel="stylesheet" href="${window.styleFilePath}"/>
      <video playsinline controls ${ref(this.videoRef)} crossorigin="anonymous">
        <source src="${this.videoUrl}" type="video/mp4" />
        <track kind="captions" label="English" src="${captions.en}" srclang="en" />
        <track kind="captions" label="Deutsch" src="${captions.de}" srclang="de" />
      </video>
    `
  }
}

window.customElements.define('ui-video', VideoPlayer)
